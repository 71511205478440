import React from 'react'
import { Link, graphql } from 'gatsby'
import Layout from '../components/Layout'
import SEO from '../components/seo'
import SwiperSwipe from '../components/Swiper'
import ScrollAnimation from 'react-animate-on-scroll'
import "animate.css/animate.min.css";
import { LazyLoadImage, LazyLoadComponent } from 'react-lazy-load-image-component'
import 'react-lazy-load-image-component/src/effects/blur.css'
import './index.css'


class IndexPage extends React.Component {
 
    
  render() {
    const siteTitle = "Start your journey to a slimmer you"
    const siteDescription = this.props.data.site.siteMetadata.description

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO title={siteTitle} description={siteDescription} />
        <>
          <section id="coolsculpting" className="component bg-white py-0">
          <div
            id="coolsculpting-banner"
            style={{
              backgroundImage:
                'url("/img/Springs-body-sculpting-procedure-female-abdomen.jpg")',
              backgroundPosition: "left",
              backgroundSize: "cover"
            }}
          />
          <div className="container">
            <div className="row">
              <div className="col-lg-6 ml-auto">
              <ScrollAnimation animateIn="fadeInRightBig" animateOnce={true}>
                <div
                  className="content pl-lg-5 py-5 py-lg-9"
                >
                  <h2 className="text-primary-blue">
                    The CoolSculpting <br />
                    Procedure
                  </h2>
                  <h6 className="text-secondary mb-4 bg-white p-2 font-weight-normal d-inline-block border">
                    Body Shaping Treatment
                  </h6>
                  <p className="font-size-18">
                    CoolSculpting freezes fat cells to sculpt away stubborn fat
                    from waistlines, thighs, hips, upper arms and chins.
                    Patients who undergo CoolSculpting say their clothes fit
                    better and they feel better about themselves and how they
                    look.
                  </p>
                  <div className="d-flex align-items-start flex-wrap mt-4">
                    <a
                      className="btn-link bg-gradient-orange text-white mr-sm-2 hvr-grow-shadow"
                      href="/"
                    >
                      Make an appointment
                    </a>
                    <Link
                      className="btn-link border text-primary-orange hvr-shadow"
                      href="/"
                      title="read more"
                    >
                      Read More
                    </Link>
                  </div>
                </div>
              </ScrollAnimation>
              </div>
            </div>
          </div>
        </section>
        <section id="emsculpt" className="component bg-white py-0">
          <div
            id="emsculpt-banner"
            style={{
              backgroundImage: 'url("/img/emsculpt-procedure.jpg")',
              backgroundPosition: "center",
              backgroundSize: "cover"
            }}
          />
          <div className="container">
            <div className="row">
              <div className="col-lg-6">
              <ScrollAnimation animateIn="fadeInLeftBig" animateOnce={true}>
                <div
                  className="content pr-lg-5 py-5 py-lg-9"
                >
                  <h2 className="text-primary-blue">
                    The Emsculpt <br />
                    Procedure
                  </h2>
                  <h6 className="text-secondary mb-4 bg-white p-2 font-weight-normal d-inline-block border">
                    Body Shaping Treatment
                  </h6>
                  <p className="font-size-18">
                    Emsculpt is the only muscle toning and building device in
                    the world and we have it right here for you. Emsculpt is
                    FDA-cleared to sculpt and build abdominal, and buttocks
                    muscles. Emsculpt is ideal for anyone who is looking to gain
                    muscle definition. So, even if you just want to speed up
                    your workout results or build new muscles, Emsculpt has you
                    covered. 
                  </p>
                  <div className="d-flex align-items-start flex-wrap mt-4">
                    <a
                      className="btn-link bg-gradient-orange text-white mr-sm-2 hvr-grow-shadow"
                      href="/"
                    >
                      Make an appointment
                    </a>
                    <Link
                      className="btn-link border text-primary-orange hvr-shadow"
                      href="/"
                      title="read more"
                    >
                      Read More
                    </Link>
                  </div>
                </div>
                </ScrollAnimation>
              </div>
            </div>
          </div>
        </section>
        <section id="why-choose-us" className="component bg-gradient-blue">
          <div className="container">
            <div className="intro text-center mb-5">
              <h2 className="text-white">Why Choose Us?</h2>
            </div>
            <div className="row">
              <div
                className="col-lg-6">
              <ScrollAnimation animateIn="fadeIn" animateOnce={true} className="row align-items-start text-white mb-4">
                <div className="col-sm-auto">
                <LazyLoadImage
                  effect="blur"
                  width={60}
                  className="mb-3 img-fluid"
                  src="/img/001-pin.svg"
                  alt="locally-owned"
                />
                </div>
                <div className="col-sm-10">
                <div className="content">
                  <h4>Locally-Owned</h4>
                  <p>
                    We are not a franchise or national chain. Springs Body
                    Sculpting was started by two physicians who live and work in
                    Colorado Springs. We are deeply invested in this community,
                    and are committed to maintaining the highest standards of
                    care. Our goal is to achieve a reputation as the premier
                    CoolSculpting provider in this area one patient at a time.
                  </p>
                </div>
                </div>
                </ScrollAnimation>
              </div>
              
              <div
                className="col-lg-6"
              >
              <ScrollAnimation animateIn="fadeIn" animateOnce={true} className="row align-items-start text-white mb-4">
                <div className="col-sm-auto">
                <LazyLoadImage
                  effect="blur"
                  width={60}
                  className="mb-3 img-fluid"
                  src="/img/003-focus.svg"
                  alt="one focus"
                />
                </div>
                <div className="col-sm-10">
                <div className="content">
                  <h4>One Focus</h4>
                  <p>
                    CoolSculpting is all we do. Because of this, we can focus on
                    consistently delivering the best possible result for each
                    individual client. We have the latest in CoolSculpting
                    technology, allowing us to complete treatments in as little
                    as 35 minutes.
                  </p>
                </div>
                </div>
                </ScrollAnimation>
              </div>
              
              
              <div
                className="col-lg-6"
              >
              <ScrollAnimation animateIn="fadeIn" animateOnce={true} className="row align-items-start text-white mb-4">
                <div className="col-sm-auto">
                <LazyLoadImage
                  effect="blur"
                  width={60}
                  className="mb-3 img-fluid"
                  src="/img/002-time.svg"
                  alt="minimal wait time"
                />
                </div>
                <div className="col-sm-10">
                <div className="content">
                  <h4>Minimal Wait Time</h4>
                  <p>
                    Want your new body sooner? We currently have minimal wait
                    times, so you can potentially be evaluated and treated on
                    the same day! Our schedule will fill fast, so reserve your
                    spot today by calling 719-633-8773.
                  </p>
                </div>
                </div>
                </ScrollAnimation>
              </div>
              
              
              <div
                className="col-lg-6"
              >
              <ScrollAnimation animateIn="fadeIn" animateOnce={true} className="row align-items-start text-white mb-4">
                <div className="col-sm-auto">
                <LazyLoadImage
                  effect="blur"
                  width={60}
                  className="mb-3 img-fluid"
                  src="/img/004-premium.svg"
                  alt="minimal wait time"
                />
                </div>
                <div className="col-sm-10">
                <div className="content">
                  <h4>Indulgent Experience</h4>
                  <p>
                    Our treatment rooms are not only private, but are designed
                    to make your CoolSculpting experience extraordinary. We have
                    the latest adjustable treatment beds, 4K ultra
                    high-definition televisions, Direct TV, Wi-Fi, movies, and
                    Sony Play Station. Get lost in all the entertainment options
                    while you transform your body!
                  </p>
                </div>
                </div>
                </ScrollAnimation>
              </div>
              
            </div>
            <div className="d-flex justify-content-center align-items-start">
              <a
                className="btn-link bg-gradient-orange text-white hvr-grow-shadow"
                href="/"
              >
                Make an appointment
              </a>
            </div>
          </div>
        </section>
        <section id="testimonials" className="component bg-white">
          <div className="container">
            <div className="intro text-center mb-5">
              <h2 className="text-primary-blue">What Our Patient's Say</h2>
            </div>
            <div className="row">
              <div className="col-md-10 mx-auto">
              <LazyLoadComponent>
                <SwiperSwipe slidesperview={1} space={0}>
                    <div className="swiper-slide">
                      <div className="testimonial text-center px-md-5">
                        <p className="font-size-24 mb-3">
                          …it’s been huge for my confidence. Recently, I was at
                          a party with some super-skinny friends with great
                          legs, and you know what? I wore shorts and it felt
                          great!”
                        </p>
                        <ul className="list-unstyled rating d-flex justify-content-center mb-0">
                          <li className="mr-1">
                            <LazyLoadImage
                              effect="blur"
                              src="/img/star.svg"
                              width={18}
                              alt="star"
                            />
                          </li>
                          <li className="mr-1">
                            <LazyLoadImage
                              effect="blur"
                              src="/img/star.svg"
                              width={18}
                              alt="star"
                            />
                          </li>
                          <li className="mr-1">
                            <LazyLoadImage
                              effect="blur"
                              src="/img/star.svg"
                              width={18}
                              alt="star"
                            />
                          </li>
                          <li className="mr-1">
                            <LazyLoadImage
                              effect="blur"
                              src="/img/star.svg"
                              width={18}
                              alt="star"
                            />
                          </li>
                          <li className="mr-1">
                            <LazyLoadImage
                              effect="blur"
                              src="/img/star.svg"
                              width={18}
                              alt="star"
                            />
                          </li>
                        </ul>
                        <p className="font-weight-bold text-uppercase mb-0">
                          Cristina
                        </p>
                        <small>Patient</small>
                      </div>
                    </div>
                    <div className="swiper-slide">
                      <div className="testimonial text-center px-md-5">
                        <p className="font-size-24 mb-3">
                          The CoolSculpting procedure was the perfect solution
                          for me. It froze off my fat, and didn’t interfere with
                          my tight schedule.{" "}
                        </p>
                        <ul className="list-unstyled rating d-flex justify-content-center mb-0">
                          <li className="mr-1">
                            <LazyLoadImage
                              effect="blur"
                              src="/img/star.svg"
                              width={18}
                              alt="star"
                            />
                          </li>
                          <li className="mr-1">
                            <LazyLoadImage
                              effect="blur"
                              src="/img/star.svg"
                              width={18}
                              alt="star"
                            />
                          </li>
                          <li className="mr-1">
                            <LazyLoadImage
                              effect="blur"
                              src="/img/star.svg"
                              width={18}
                              alt="star"
                            />
                          </li>
                          <li className="mr-1">
                            <LazyLoadImage
                              effect="blur"
                              src="/img/star.svg"
                              width={18}
                              alt="star"
                            />
                          </li>
                          <li className="mr-1">
                            <LazyLoadImage
                              effect="blur"
                              src="/img/star.svg"
                              width={18}
                              alt="star"
                            />
                          </li>
                        </ul>
                        <p className="font-weight-bold text-uppercase mb-0">
                          Kris
                        </p>
                        <small>Patient</small>
                      </div>
                    </div>
                    <div className="swiper-slide">
                      <div className="testimonial text-center px-md-5">
                        <p className="font-size-24 mb-3">
                          The procedure was painless for me, and the results
                          last. I don’t have to suck in my stomach any more, and
                          I couldn’t be happier!
                        </p>
                        <ul className="list-unstyled rating d-flex justify-content-center mb-0">
                          <li className="mr-1">
                            <LazyLoadImage
                              effect="blur"
                              src="/img/star.svg"
                              width={18}
                              alt="star"
                            />
                          </li>
                          <li className="mr-1">
                            <LazyLoadImage
                              effect="blur"
                              src="/img/star.svg"
                              width={18}
                              alt="star"
                            />
                          </li>
                          <li className="mr-1">
                            <LazyLoadImage
                              effect="blur"
                              src="/img/star.svg"
                              width={18}
                              alt="star"
                            />
                          </li>
                          <li className="mr-1">
                            <LazyLoadImage
                              effect="blur"
                              src="/img/star.svg"
                              width={18}
                              alt="star"
                            />
                          </li>
                          <li className="mr-1">
                            <LazyLoadImage
                              effect="blur"
                              src="/img/star.svg"
                              width={18}
                              alt="star"
                            />
                          </li>
                        </ul>
                        <p className="font-weight-bold text-uppercase mb-0">
                          Estelle
                        </p>
                        <small>Patient</small>
                      </div>
                      </div>

                  </SwiperSwipe>
                  </LazyLoadComponent>
              
              </div>
            </div>
            <div className="d-flex justify-content-center align-items-start mt-5">
              <a
                className="btn-link border text-primary-orange hvr-shadow"
                href="/"
              >
                view all testimonial
              </a>
            </div>
          </div>
        </section>
        <section id="mailing-list" className="component bg-gradient-blue">
          <div className="container">
          <ScrollAnimation animateIn="fadeInDown" animateOnce={true}>
            <div
              className="row align-items-center"
            >
              <div className="col-md-8">
                <div className="content">
                  <h6 className="mb-2 p-2 font-weight-medium d-inline-block border text-light">
                    Sign Up To Stay In Touch
                  </h6>
                  <h3 className="text-white">
                    Join our mailing list to get interesting new, updates & more
                    delivered to your inbox.
                  </h3>
                </div>
              </div>
              <div className="col">
                <div className="d-flex justify-content-start align-items-start mt-4">
                  <a
                    className="btn-link bg-gradient-orange text-white hvr-grow-shadow"
                    href="https://visitor.r20.constantcontact.com/manage/optin?v=0015vUZnZWeEJuMzC-RPdaFKbcOIxIW-f5WIJ8tZG_dJZD6M4kw72OBZ_gcgnQkYJNKPoeLPg131eAO02e2ZT8Y-iExypxXDlEcLkBun9cQnlH89xnT0MOd7J5cKy_-fsQYGCCCp2WEiewMpYCOaiwePycx2cO5SuavhJBwhfR_wMFbzKZM1omdb19Jqx9-A47L0wVBkfKecbwNkZH1eZFH7g%3D%3D" 
                    target="_blank" rel="noopener noreferrer"
                  >
                    Sign up now
                  </a>
                </div>
              </div>
            </div>
            </ScrollAnimation>
          </div>
        </section>
        <section id="news" className="component bg-light">
          <div className="container">
            <div className="intro text-center mb-5">
              <h2 className="text-primary-blue">Recent News</h2>
            </div>
            <div className="row">
              <div
                className="col-md-6 col-lg-4 mb-4"
                data-aos="fade"
                data-aos-once="true"
              >
              <ScrollAnimation animateIn="fadeIn" animateOnce={true}>
                <div className="card shadow card-blog hvr-grow-shadow bg-white border-0">
                  <div
                    className="card-lead-image"
                    style={{
                      backgroundImage:
                        'url("/img/popsicle-Cryolipolysis.jpg")'
                    }}
                  />
                  <div className="card-body">
                    <div className="d-flex align-items-start card-blog-title">
                      <div className="col-3 card-blog-created bg-primary-blue text-white mb-3 px-0">
                        <h3 className="font-weight-normal mb-0">31</h3>
                        <h6 className="mb-0">Oct 2016</h6>
                      </div>
                      <div className="col-9 pr-0">
                        <h4>
                          <a href="/">
                            From Popsicles to Cryolipolysis to CoolSculpting
                          </a>
                        </h4>
                      </div>
                    </div>
                    <p className="card-text">
                      Years ago, scientists at Harvard University observed that
                      some children who ate popsicles got dimples in their
                      cheeks. The scientists—Dieter Manstein, MD and R. Rox
                    </p>
                  </div>
                </div>
                </ScrollAnimation>
              </div>
              <div
                className="col-md-6 col-lg-4 mb-4"
                data-aos="fade"
                data-aos-once="true"
              >
              <ScrollAnimation animateIn="fadeIn" animateOnce={true}>
                <div className="card shadow card-blog hvr-grow-shadow bg-white border-0">
                  <div
                    className="card-lead-image"
                    style={{
                      backgroundImage:
                        'url("/img/coolsculpting-procedure-female.jpg")'
                    }}
                  />
                  <div className="card-body">
                    <div className="d-flex align-items-start card-blog-title">
                      <div className="col-3 card-blog-created bg-primary-blue text-white mb-3 px-0">
                        <h3 className="font-weight-normal mb-0">31</h3>
                        <h6 className="mb-0">Oct 2016</h6>
                      </div>
                      <div className="col-9 pr-0">
                        <h4>
                          <a href="/">
                            How Cool Is The CoolSculpting Procedure?
                          </a>
                        </h4>
                      </div>
                    </div>
                    <p className="card-text">
                      As the name suggests, the CoolSculpting procedure can be a
                      little chilly. Controlled cooling is delivered via an
                      applicator to freeze the targeted fat. Though
                    </p>
                  </div>
                </div>
                </ScrollAnimation>
              </div>
              <div
                className="col-md-6 col-lg-4 d-md-none d-lg-inline mb-4"
                data-aos="fade"
                data-aos-once="true"
              >
              <ScrollAnimation animateIn="fadeIn" animateOnce={true}>
                <div className="card shadow card-blog hvr-grow-shadow bg-white border-0">
                  <div
                    className="card-lead-image"
                    style={{
                      backgroundImage:
                        'url("/img/torso-pastel-gray-770x560.jpg")'
                    }}
                  />
                  <div className="card-body">
                    <div className="d-flex align-items-start card-blog-title">
                      <div className="col-3 card-blog-created bg-primary-blue text-white mb-3 px-0">
                        <h3 className="font-weight-normal mb-0">19</h3>
                        <h6 className="mb-0">Oct 2016</h6>
                      </div>
                      <div className="col-9 pr-0">
                        <h4>
                          <a href="/">CoolSculpting for Men</a>
                        </h4>
                      </div>
                    </div>
                    <p className="card-text">
                      CoolSculpting isn’t just something women are taking
                      advantage of to achieve their aesthetic goals–men are also
                      jumping on board to take control of their dreams
                    </p>
                  </div>
                </div>
                </ScrollAnimation>
              </div>
            </div>
            <div className="d-flex justify-content-center align-items-start mt-4">
              <a
                className="btn-link border text-primary-orange hvr-shadow"
                href="/"
              >
                view all Latest News
              </a>
            </div>
          </div>
        </section>          
        </>
      </Layout>
    )
  }
}

export default IndexPage

export const indexPageQuery = graphql`
  query indexPageQuery {
    site {
      siteMetadata {
        title
        author
        description
      }
    }
  }
`
